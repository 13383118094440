<template>
  <v-row no-gutters>
    <v-col
      cols="7"
      class="pl-6 pr-2"
    >
      <label class="input-label text-left" :for="dateId">
        {{ label.date + ' ' + (utc ? '(UTC)' : '(Local)') }}
      </label>
      <v-menu
        v-model="menuDate1"
        :close-on-content-click="false"
        max-width="290"
        :disabled="$store.state.admin ? true : false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :id="dateId"
            :value="computedDateFormattedDatefns"
            hide-details
            outlined
            rounded
            color="primary"
            class="mb-4"
            v-bind="attrs"
            v-on="on"
            @click:clear="Date1 = null"
            :disabled="$store.state.admin ? true : false"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="Date1"
          @change="menuDate1 = false"
        ></v-date-picker>
      </v-menu>
    </v-col>
    <v-col
      cols="5"
      class="pr-6 pl-2"
    >
      <label class="input-label text-left" :for="dateId+'Time'">
        {{ label.time + ' ' + (utc ? '(UTC)' : '(Local)') }}
      </label>
      <v-menu
        ref="menuTime1"
        v-model="menuTime1"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="Time1"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
        :disabled="$store.state.admin ? true : false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="Time1"
            :id="dateId+'Time'"
            hide-details
            outlined
            rounded
            color="primary"
            class="mb-4"
            v-bind="attrs"
            v-on="on"
            :disabled="$store.state.admin ? true : false"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="menuTime1"
          v-model="Time1"
          format="24hr"
          full-width
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menuTime1 = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menuTime1.save(Time1)"
          >
            OK
          </v-btn>
        </v-time-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import { format, parseISO } from 'date-fns'

export default {
  props: {
    dateId: {
      type: String,
      default: ''
    },
    utc: {
      type: Boolean,
      default: false
    },
    label: {
      type: Object,
      default: () => ({
        date: '',
        time: ''
      })
    },
    value: {
      type: String,
      default: ''
    },
    format: {
      type: String,
      default: 'yyyy-MM-dd'
    }
  },
  data: (vm) => ({
    Date1: format(parseISO(new Date(vm.value).toISOString()), vm.format),
    menuDate1: false,
    Time1: '00:00',
    menuTime1: false
  }),
  computed: {
    computedDateFormattedDatefns () {
      return this.Date1 ? format(parseISO(new Date(this.Date1).toISOString()), this.format) : format(parseISO(new Date().toISOString()), this.format)
    }
  },
  watch: {
    dateTime: function () {
      this.sync()
    },
    utc: function () {
      this.sync()
    }
  },
  mounted () {
    this.sync()
  },
  methods: {
    sync () {
      if (this.utc) {
        this.Date1 = format(parseISO(new Date(this.$getUTC(this.value)).toISOString()), 'yyyy-MM-dd')
        this.Time1 = this.$localDT(this.$getUTC(this.value), 'time')
      } else {
        this.Date1 = format(parseISO(new Date(this.value).toISOString()), 'yyyy-MM-dd')
        this.Time1 = this.$localDT(this.value, 'time')
      }
      let dstart = this.Date1 + ' ' + this.Time1
      if (this.utc) {
        dstart = dstart.trim().replace(' ', 'T') + ':00.000Z'
      }
      this.$emit('input', dstart)
    }
  }
}
</script>
